<template>
  <el-container>
    <el-header height="80px"></el-header>
    <el-main>
      <el-row>
        <el-col>
          <h2>点数提货系统</h2>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="spanValue" :offset="offsetValue">
          <el-tabs
            v-loading.fullscreen.lock="loading"
            v-model="activeName"
            type="border-card"
            @tab-click="handleClick"
          >
            <el-tab-pane label="点数提货" name="first">
              <el-form ref="form" :model="form" :rules="dataRule">
                <span>淘宝订单号</span>
                <el-form-item prop="tbOrderId">
                  <el-input
                    v-model="form.tbOrderId"
                    placeholder="请输入淘宝订单号"
                  ></el-input>
                </el-form-item>
                <span>Steam个人主页链接</span>
                <el-form-item prop="steamHomeUrl">
                  <el-input
                    v-model="form.steamHomeUrl"
                    placeholder="请输入Steam个人主页链接"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="extractGift()"
                    >提货</el-button
                  >
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="提货进度查询" name="second">
              <el-form ref="queryForm" :model="queryForm" :rules="queryRule">
                <span>淘宝订单号</span>
                <el-form-item prop="queryTbOid">
                  <el-input
                    v-model="queryForm.queryTbOid"
                    placeholder="请输入淘宝订单号"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="queryProgress()"
                    >查询</el-button
                  >
                </el-form-item>
              </el-form>
              <el-table
                :data="tableData"
                style="width: 100%"
                v-show="showTable"
                :fit="false"
                height="250"
              >
                <el-table-column prop="tbOid" label="订单号" width="90">
                </el-table-column>
                <el-table-column prop="account" label="昵称"> </el-table-column>
                <el-table-column prop="orderPoint" label="订单点数" width="90">
                </el-table-column>
                <el-table-column prop="presentedPoint" label="已送点数" width="90">
                </el-table-column>
                <el-table-column prop="status" label="状态" width="60">
                  <template slot-scope="scope">
                    <p v-if="tableData[scope.$index].status == 1">等待提货</p>
                    <p v-if="tableData[scope.$index].status == 2">
                      点数赠送中
                    </p>
                    <p v-if="tableData[scope.$index].status == 3">
                      点数赠送完成
                    </p>
                  </template>
                </el-table-column>
                <el-table-column prop="sendTime" :formatter="receiptDateFormat" label="到账时间" width="90">
                </el-table-column>
                <el-table-column header-align="center" align="center" width="90" label="操作">
                  <el-button type="text" size="small" @click="openNewPage('_start')">发货前截图
                  </el-button>
                  <el-button type="text" size="small" @click="openNewPage('_end')">发货后截图
                  </el-button>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <div class="empty"></div>
    </el-main>
    <el-footer height="60px">
      <h2>常见问题</h2>
      <el-row>
        <el-col :span="spanValue" :offset="offsetValue">
          <el-collapse>
          <el-collapse-item
              title="点数什么时候能到账？"
              name="1"
            >
              <div>提货之后点数赠送只需要几分钟</div>
              <div>点数赠送完成之后根据Steam规则需要14天之后到账，到账时间可以在提货进度查询中看到</div>
            </el-collapse-item>
            <el-collapse-item
              title="登录不上Steam或者打不开个人资料页面"
              name="2"
            >
              <div>可以下载UU加速器加速Steam商店，是免费的。</div>
              <div>下载地址：https://uu.163.com</div>
            </el-collapse-item>
            <el-collapse-item title="如何使用Steam客户端查看Steam个人资料链接" name="3">
              <div>
                登录Steam客户端，打开个人资料页面，在页面的任意空白处点击鼠标右键，复制网页URL即可
              </div>
              <br />
              <el-image
                style="width: 100px; height: 100px"
                :src="require('@/assets/view-pc-steam-url.png')"
                :preview-src-list="[require('@/assets/view-pc-steam-url.png')]"
              >
              </el-image>
            </el-collapse-item>
            <el-collapse-item title="如何使用Steam手机端查看Steam个人资料链接" name="4">
              <el-image
                style="width: 100px; height: 100px"
                :src="require('@/assets/view-mobile-steam-url.png')"
                :preview-src-list="[require('@/assets/view-mobile-steam-url.png')]"
              >
              </el-image>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-row>
    </el-footer>
  </el-container>
</template>
<script>
export default {
  name: "app",
  data() {
    return {
      spanValue: 12,
      offsetValue: 6,
      activeName: "first",
      loading: false,
      showTable: false,
      form: {
        tbOrderId: "",
        steamHomeUrl: "",
      },
      queryForm: {
        queryTbOid: "",
      },
      dataRule: {
        tbOrderId: [
          { required: true, message: "淘宝订单号不能为空", trigger: "blur" },
        ],
        steamHomeUrl: [
          { required: true, message: "steam个人链接不能为空", trigger: "blur" },
        ],
      },
      queryRule: {
        queryTbOid: [
          { required: true, message: "淘宝订单号不能为空", trigger: "blur" },
        ],
      },
      tableData: [],
    };
  },
  mounted: function () {
    if (this._isMobile()) {
      this.spanValue = 24;
      this.offsetValue = 0;
    } else {
      this.spanValue = 12;
      this.offsetValue = 6;
    }
  },
  created(){
    if(this.$route.query.tbOid){
      this.form.tbOrderId = this.$route.query.tbOid;
      this.queryForm.queryTbOid = this.$route.query.tbOid;
    }
  },
  methods: {
    openNewPage: function(fileName){
      console.log(this.queryForm.queryTbOid)
      window.open("https://guoguo.cpu88.cn/point-image/"+this.queryForm.queryTbOid+fileName+".html");
    },
    receiptDateFormat:function(row,column){
      if(row[column.property]){
        var sendTime = new Date(row[column.property]);
        if(sendTime == undefined){return ''};
        return this.$moment(sendTime.setDate(sendTime.getDate()+14)).format("YYYY-MM-DD HH:mm:ss");
      }
    },
    // 判断移动端还是pc端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    onSubmit() {
    },
    handleClick(tab, event) {
    },
    extractGift() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("guest/gift/exchange", this.form)
            .then(({ data }) => {
              if (data && data.code === 0) {
                this.loading = false;
                this.$message({
                  message: "操作成功",
                  type: "success",
                  duration: 1500,
                });
              } else {
                this.loading = false;
                this.$message.error(data.msg);
              }
            })
            .catch((e) => {
              this.loading = false;
              this.$message({
                message: "网络或程序异常！" + e,
                type: "error",
              });
            });
        }
      });
    },
    queryProgress() {
      this.tableData = [];
      this.$refs["queryForm"].validate((valid) => {
        if (valid) {
          this.$http
            .get("guest/gift/queryProgress/" + this.queryForm.queryTbOid)
            .then(({ data }) => {
              if (data && data.code === 0) {
                this.showTable = true;
                this.tableData.push(data.progress);
              } else {
                this.$message.error(data.msg);
              }
            })
            .catch((e) => {
              this.$message({
                message: "网络或程序异常！" + e,
                type: "error",
              });
            });
        }
      });
    },
  },
};
</script>

<style>
.el-header,
.el-footer {
  text-align: center;
  line-height: 80px;
}

.el-main {
  color: #b3c0d1;
  text-align: center;
}

.el-main span {
  text-align: left;
}

/* body > .el-container {
    margin-bottom: 40px;
  } */

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
el-card {
  padding: 0px;
}
.empty,
el-footer {
  height: 100px;
}
</style>
